import { Fragment } from 'react'
import { Typography } from 'design-system'

import { breakLineRegex, strongRegex } from 'utils'
import { handleLetterHighlights } from 'utils'


const formatMessageText = (text, sender_type, search, messageIdSearch, fontWeight = '600') => {
  const handleFormatContent = (textParams) => {
    if (search && textParams && messageIdSearch) {
      return handleLetterHighlights({ textParams, search }) || textParams;
    }
    return textParams
  }
  const lines = text.split(breakLineRegex)

  return lines.map((line, lineIndex) => {
    const parts = line.split(strongRegex)

    const formatPart = (part) => handleFormatContent ? handleFormatContent(part) : part
    return (
      <Fragment key={lineIndex}>
        {parts.map((part, partIndex) =>
          partIndex % 2 === 1 ? (
            <Typography
              as='span'
              fontWeight={fontWeight}
              fontSize={sender_type === 'internal' ? '12px' : '14px'}
              color='grey.500'
              key={`${lineIndex}-${partIndex}`}
            >
              {formatPart(part)}
            </Typography>
          ) : (
            formatPart(part)
          ),
        )}
        {lineIndex < lines.length - 1 && <br />}
      </Fragment>
    )
  })
}

export default formatMessageText
