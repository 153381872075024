import { useEffect, useRef } from 'react'
import { colors } from 'configs'
import { Button, Column, Icon, Input, Row, TextArea } from 'design-system'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { MAX_CONTENT_LENGTH } from 'components/modals/ModalCreateTemplate/templateOptions'

const TemplateTextVariants = ({ name, ...rest }) => {
  const {
    register,
    getValues,
    control,
    setValue,
    formState: { errors },
    watch,
  } = useFormContext()

  const inputRef = useRef(null)

  const { fields, append, remove } = useFieldArray({ name: `${name}_variants`, control })

  const contentWatch = watch('content')

  const insertAtCursor = (ref, content, key) => {
    if (!ref.current) return

    const input = ref.current
    const start = input.selectionStart
    const end = input.selectionEnd

    const newText = content.slice(0, start) + key + content.slice(end)

    return newText
  }

  const insertCurrentFields = () => {
    if (!contentWatch) return

    const keysRegex = /{{(\d+)}}/g
    const matches = [...contentWatch.matchAll(keysRegex)]

    const uniqueKeys = [...new Set(matches.map((match) => match[0]))].sort((a, b) => {
      const numA = parseInt(a.match(/\d+/)[0], 10)
      const numB = parseInt(b.match(/\d+/)[0], 10)
      return numA - numB
    })

    const keyMapping = uniqueKeys.reduce((acc, key, index) => {
      acc[key] = `{{${index + 1}}}`
      return acc
    }, {})

    let updatedContent = contentWatch.replace(keysRegex, (match) => keyMapping[match] || match)

    const keys = Object.values(keyMapping).map((newKey, index) => ({
      key: newKey,
      text: fields[index]?.text || '',
    }))

    append(keys)
    setValue(name, updatedContent)
  }

  const handleCreateVariant = () => {
    const key = `{{${fields.length + 1}}}`
    const content = insertAtCursor(inputRef, contentWatch, key)

    setValue(name, content)
    append({ key, text: '' })
  }

  const handleChangeText = (value, index) => {
    contentWatch.replace(fields[index].key, value)
    setValue(`${name}_variants.${index}.text`, value)
  }

  const handleRemoveVariant = (field) => {
    const newContent = contentWatch.replaceAll(field.key, '')
    setValue(name, newContent)
    remove(field.id)
  }

  useEffect(() => {
    insertCurrentFields()

    return () => remove()
  }, [contentWatch])

  return (
    <Column {...rest}>
      <TextArea
        emojis
        setValue={setValue}
        getValues={getValues}
        error={errors?.[name]}
        required
        errorMessage={errors?.content?.message}
        helperText={`${MAX_CONTENT_LENGTH - getValues()?.content?.length} caracteres`}
        watcher={contentWatch}
        inputRef={inputRef}
        {...register(name, {
          required: { value: true, message: 'Por favor, insira o texto do corpo da template' },
          maxLength: {
            value: MAX_CONTENT_LENGTH,
            message: `O texto do conteúdo não pode exceder ${MAX_CONTENT_LENGTH} caracteres.`,
          },
        })}
        {...rest}
      />
      <Column mt={fields?.length > 0 ? '16px' : '0'} gap='16px'>
        {fields.map((field, index) => (
          <Row
            p='16px'
            borderRadius='8px'
            key={field.id}
            background={fields?.length > 0 ? colors.grey[25] : colors.white}
            gap='16px'
            alignItems='center'
          >
            <Input maxWidth='94px' label='Variável' {...register(`${name}_variants.${index}.key`)} disabled />
            <Input
              maxWidth='200px'
              tabIndex='1'
              label='Valor de exemplo'
              {...register(`${name}_variants.${index}.text`)}
              onChange={(e) => handleChangeText(e.target.value, index)}
            />
            <Row mt='16px' alignItems='center'>
              <Icon icon='Trash' color='danger' onClick={() => handleRemoveVariant(field)} cursor='pointer' />
            </Row>
          </Row>
        ))}
      </Column>
      <Row mt='16px' justifyContent='flex-end'>
        <Button iconLeft='Plus' variant='text' color='secondary' onClick={() => handleCreateVariant(getValues(name))}>
          Adicionar variável
        </Button>
      </Row>
    </Column>
  )
}

export default TemplateTextVariants
