import { robotAlert } from 'assets/ilustrations'
import { Button, Column, Helper, Image, Modal, ModalBody, ModalFooter, ModalHeader, Typography } from 'design-system'

const ModalConfirmation = ({ title, onClose, message, content, handler, type, helper, helperProps, ...props }) => {
  return (
    <Modal open={open} onClose={onClose} {...props} size='sm'>
      <ModalHeader border='none' onClose={onClose} />
      <ModalBody>
        <Column alignItems='center' gap='8px'>
          <Image src={robotAlert} width='115px' />
          <Typography textAlign='center' fontSize='16px' fontWeight='600'>
            {title}
          </Typography>
          <Typography textAlign='center' maxWidth='300px'>
            {message}
          </Typography>
          {content && content}
        </Column>
        {helper && <Helper mt='8px' {...helperProps} />}
      </ModalBody>
      <ModalFooter>
        <Button variant='text' color='text' onClick={() => onClose()}>
          Cancelar
        </Button>
        <Button color={type} onClick={handler}>
          Sim, excluir
        </Button>
      </ModalFooter>
    </Modal>
  )
}

ModalConfirmation.defaultProps = {
  size: 'md',
  content: null,
  handler: () => {},
}

export default ModalConfirmation
