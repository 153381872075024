import { Column, Icon, Row, Tooltip, Typography } from 'design-system'
import { getThemeColor, hexToRGBA } from 'helpers'

const Alert = ({ color, text, icon, title, helper, ...props }) => {
  return (
    <Row
      p='12px'
      gap='4px'
      borderRadius='7px 4px 4px 7px'
      border='1px solid'
      borderLeftWidth='5px'
      alignItems='center'
      borderColor={color}
      backgroundColor={hexToRGBA(getThemeColor(color), 0.05)}
      {...props}
    >
      <Tooltip title={helper}>
        <Icon icon={icon} color={color} />
      </Tooltip>
      {title && (
        <Typography color={color} fontWeight='600' whiteSpace='nowrap'>
          {title}
        </Typography>
      )}
      <Column>
        <Typography>{text}</Typography>
      </Column>
    </Row>
  )
}

Alert.defaultProps = {
  icon: 'Alert',
  color: 'primary',
  title: '',
  text: '',
}

export default Alert
