import { memo } from 'react'
import axios from 'axios'
import styled from 'styled-components'
import { Column, Checkbox, Row, Tooltip } from 'design-system'
import messagesTypeDict from './messagesTypeDict'
import MessageFooter from './MessageFooter'
import { colors } from 'configs'
import { hexToRGBA, isDateInPast, notify } from 'helpers'
import MessageReply from './MessageReply'
import PopoverReplyMessage from 'components/popovers/PopoverReplyMessage'
import { CustomContactActionButton } from './Contact'

const fileStyle = {
  pdf: { padding: '0', maxWidth: '424px' },
  audio: { minWidth: '300px', maxWidth: '424px' },
  image: { width: 'fit-content', maxWidth: '424px' },
  video: { width: '280px', maxWidth: '424px' },
  other: { maxWidth: '424px' },
}

const messageTypeStyles = {
  text: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  list: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  file: (files) => ({
    ...fileStyle[files[0]?.file_category?.value],
  }),
  template: () => ({ width: 'fit-content', maxWidth: '424px', minWidth: '112px' }),
  location: () => ({ width: '254px', height: '200px', minWidth: '112px' }),
  contact: () => ({ width: '220px' }),
}

const messageFromDict = {
  attendant: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 0 8px',
    background: hexToRGBA(colors.violet[100], 0.6),
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  contact: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px 8px 8px 0',
    background: colors.grey[50],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  system: ({ message_type, files }) => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    maxWidth: '250px',
    background: colors.blue[60],
    ...(messageTypeStyles[message_type](files) || {}),
  }),
  internal: () => ({
    padding: '8px 8px 4px 8px',
    borderRadius: '8px',
    background: colors.blue[60],
  }),
}

const containerDict = {
  attendant: {
    alignItems: 'flex-end',
  },
  contact: {
    alignItems: 'flex-start',
  },
  system: {
    alignItems: 'flex-end',
  },
  internal: {
    alignItems: 'center',
  },
}

const TicketMessage = ({
  message,
  index,
  dispatch,
  actionRepliedMessage,
  replyMenuState,
  containerMessageRef,
  selectedticket,
  showCheckbox,
  onClickForward,
  onSelectedForwards,
  messageSelected,
  setExpand,
  search,
  messageIdSearch,
  type,
  ...props
}) => {

  const isBlockedInteraction =
    selectedticket &&
    (isDateInPast(selectedticket.conversation_expiration_datetime) || selectedticket.status === 'pending' || selectedticket.status === 'closed')

  const {
    id,
    message_type,
    sender_type,
    sent_through_whatsapp,
    created_at,
    status,
    files,
    user_sender,
    replied_message,
    body,
    fail_code,
    fail_reason,
  } = message || {}

  const [replyMenu, setReplyMenu] = replyMenuState || []

  const messageFrom = sent_through_whatsapp ? 'attendant' : sender_type

  const messageInput = messagesTypeDict[message_type] || messagesTypeDict['text']

  const handleContextMenu = (e) => {
    e.preventDefault()
    if (replyMenu) return setReplyMenu(null)

    setReplyMenu && setReplyMenu(() => message)
  }

  const handleReplyMessage = () => {
    dispatch({
      type: actionRepliedMessage.SET_REPLY,
      payload: message,
    })
    setReplyMenu(false)
  }

  const handleForwardClick = () => {
    onClickForward(true)
    setReplyMenu(false)
    onSelectedForwards(id)
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(body.text)
    setReplyMenu(false)
    notify.success('Mensagem copia com sucesso!')
  }

  const handleSaveFile = () => {
    download()
    setReplyMenu(false)
  }

  const hasCheckedById = messageSelected && messageSelected.filter((item) => item === id).length > 0

  const isMessageAvailableForwards =
    showCheckbox === true && (message_type === 'text' || message_type === 'file') && (messageFrom === 'attendant' || messageFrom === 'contact')

  const download = async () => {
    const { file, file_name } = files[0];
    try {
      const response = await axios.get(file, {
        responseType: 'blob',
        withCredentials: false,
      });
      const blob = new Blob([response.data]);
      const link = document.createElement("a");
      const downloadUrl = URL.createObjectURL(blob);
      link.href = downloadUrl;
      link.setAttribute("download", file_name);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(downloadUrl);
    } catch (e) {
      console.error('Error downloading file:', e)
      notify.error('Não foi possível baixar o arquivo. Tente novamente.')
    }
  };

  return (
    <Column
      id={type ? `message_${id}_${type}` : `message_${id}`}
      key={`${index}_${id}`}
      width='100%'
      {...containerDict[messageFrom]}
      padding='4px 10px'
      {...props}
      cursor={isMessageAvailableForwards ? 'pointer' : 'initial'}
      background={hasCheckedById ? colors.grey[20] : ''}
      onClick={isMessageAvailableForwards ? () => onSelectedForwards(id) : () => { }}
    >
      <Row alignItems='center' gap='8px' flexDirection={messageFrom === 'contact' ? 'row' : 'row-reverse'}>
        {showCheckbox &&
          ((isMessageAvailableForwards && <Checkbox checked={hasCheckedById} />) ||
            (sender_type !== 'internal' && (
              <Tooltip title='Não é possível encaminhar essa mensagem' position='bottom'>
                <Checkbox disabled />
              </Tooltip>
            )))}
        <CustomStyles position='relative' {...messageFromDict[messageFrom]({ message_type, files })} onContextMenu={handleContextMenu}>
          {replied_message && <MessageReply repliedMessage={replied_message} senderType={sender_type} />}
          {messageInput({ message, setExpand, sender_type, search, messageIdSearch })}
          <MessageFooter
            createdAt={created_at}
            userSender={user_sender}
            status={status}
            senderType={sender_type}
            failCode={fail_code}
            failReason={fail_reason}
          />
          {message_type === 'contact' && <CustomContactActionButton contacts={message?.contacts} containerMessageRef={containerMessageRef} />}
          {replyMenu?.id === id && (!isBlockedInteraction || body?.text) && (
            <PopoverReplyMessage
              messageFrom={messageFrom}
              forwardDisabled={message_type !== 'text' && message_type !== 'file'}
              copyDisabled={messageFrom === 'attendant'}
              isFile={message_type === 'file'}
              onSave={handleSaveFile}
              open={replyMenu}
              right='0px'
              top='-40px'
              {...(!isBlockedInteraction && { replyClick: handleReplyMessage })}
              {...(body?.text && { copyClick: handleCopy })}
              forwardClick={handleForwardClick}
            />
          )}
        </CustomStyles>
      </Row>
    </Column>
  )
}

const CustomStyles = styled(Column)`
  & .conversation__user_sender:first-child {
    visibility: hidden;
  }

  &:hover .conversation__user_sender:first-child {
    visibility: visible;
  }
`

export default memo(TicketMessage)
