import Icon from 'design-system/Icon'
import Row from 'design-system/Row'
import Typography from 'design-system/Typography'

const Helper = ({ text, color, ...props }) => {
  return (
    <Row gap='4px' {...props}>
      <Icon icon='Alert' color={color} />
      <Typography color={color}>{text}</Typography>
    </Row>
  )
}
export default Helper
