import { Card, Column, Input, Select } from 'design-system'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { ActionsButton, TemplateTextVariants } from 'components/specific/templates/CreateTemplate'

import headerTypeDict from './headerType'

import { headerTypesOptions, MAX_BUTTONS_LIMIT, MAX_CONTENT_LENGTH, MAX_FOOTER_LENGTH, MAX_HEADER_LENGTH } from '../templateOptions'
import ActionContainer from './ActionsContainer'
import { useEffect } from 'react'

const ModelConfig = () => {
  const {
    control,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
    setError,
    clearErrors,
  } = useFormContext()

  const { fields, append, remove, move, update } = useFieldArray({ name: 'containers', control })
  const [headerType, footer, header, content] = watch(['header.header_type', 'footer', 'header.text', 'content'])

  const countTypeLimit = fields.reduce(
    (acc, field) => {
      field.buttons.forEach(({ button_type }) => {
        acc[button_type] = (acc[button_type] || 0) + 1
        acc.total = acc.total + 1
      })
      return acc
    },
    { total: 0 },
  )

  const handleRemoveFieldFromContainer = (fIndex, cIndex) => {
    const updatedFields = fields[cIndex].buttons.filter((_, i) => i !== fIndex)

    if (updatedFields?.length !== 0) {
      update(cIndex, { ...fields[cIndex], buttons: updatedFields })
    } else {
      remove(cIndex)
    }
  }

  useEffect(() => {
    if (header?.length > MAX_HEADER_LENGTH) {
      setError('header.text', {
        type: 'maxLength',
        message: `O texto do header não pode exceder ${MAX_HEADER_LENGTH} caracteres.`,
      })
    } else {
      clearErrors('header.text')
    }
    if (footer?.length > MAX_HEADER_LENGTH) {
      setError('footer', {
        type: 'maxLength',
        message: `O texto do footer não pode exceder ${MAX_FOOTER_LENGTH} caracteres.`,
      })
    } else {
      clearErrors('footer')
    }
    if (content?.length > MAX_CONTENT_LENGTH) {
      setError('content', {
        type: 'maxLength',
        message: `O texto do conteúdo não pode exceder ${MAX_CONTENT_LENGTH} caracteres.`,
      })
    } else {
      clearErrors('content')
    }
  }, [header, footer, content])

  return (
    <Column gap='16px' width='100%'>
      <Card p='16px'>
        <Column gap='16px'>
          <Select
            maxWidth='200px'
            label='Selecione o tipo de cabeçalho'
            options={headerTypesOptions}
            control={control}
            callBack={() => setValue('header.text', '')}
            {...register('header.header_type')}
          />
          {headerType?.value && headerTypeDict[headerType.value]({ getValues, register, errors })}
          <TemplateTextVariants label='Insira o texto da sua mensagem' name='content' />
          <Input
            label='Texto do rodapé'
            placeholder='Digite seu texto'
            {...register('footer', {
              maxLength: {
                value: MAX_FOOTER_LENGTH,
                message: `O texto do rodapé não pode exceder ${MAX_FOOTER_LENGTH} caracteres.`,
              },
            })}
            error={errors?.footer}
            errorMessage={errors?.footer?.message}
            helperText={`${MAX_FOOTER_LENGTH - getValues()?.footer?.length} caracteres`}
          />
        </Column>
      </Card>
      <Card>
        <ActionsButton
          fields={fields}
          update={update}
          append={append}
          countTypeLimit={countTypeLimit}
          limit={countTypeLimit?.total === MAX_BUTTONS_LIMIT}
          remove={remove}
          setValue={setValue}
          getValues={getValues}
        />
        <Column mt='16px' gap='16px'>
          {fields.map((field, index) => (
            <ActionContainer
              id={field.id}
              key={field.id}
              field={field}
              containerIndex={index}
              errors={errors}
              register={register}
              remove={remove}
              removeField={handleRemoveFieldFromContainer}
              move={move}
            />
          ))}
        </Column>
      </Card>
    </Column>
  )
}

export default ModelConfig
