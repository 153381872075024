import React, { forwardRef } from 'react'
import SelectLib from 'react-select/async'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'
import { Controller } from 'react-hook-form'

import Typography from '../Typography'
import { colors } from 'configs'
import TextLabelHelper from 'design-system/TextLabelHelper'
import Row from 'design-system/Row'
import Icon from 'design-system/Icon'

const styles = compose(space, layout)

const circle = (color = 'transparent') => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'white',
  gap: '4px',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    height: 10,
    width: 10,
  },
})

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '20px',
    border: `1px solid ${state.isFocused ? colors.blue[80] : colors.grey[50]}`,
    padding: '0px',
    color: colors.grey[300],
    fontSize: '14px',
    '&:hover': {
      borderColor: colors.blue[80],
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0px 8px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '1.7px 8px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '1050',
    width: 'max-content',
    minWidth: '100%',
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
  option: (styles) => ({
    ...styles,
    color: colors.grey[300],
    backgroundColor: 'white',
    ':hover': { backgroundColor: colors.blue[50] },
  }),
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
}

const SelectAsync = forwardRef(
  ({ label, maxWidth, keys, name, control, defaultValue, error, errorMessage, required, rules, callBack, ...props }, ref) => {
    const handleChange = (data, field) => {
      field && field?.onChange(data)
      callBack && callBack(data)
      return data
    }

    return (
      <Base maxWidth={maxWidth}>
        <TextLabelHelper label={label} required={required} helper={props.helper} />
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <SelectLib
                ref={ref}
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={() => 'Nenhum resultado encontrado.'}
                styles={selectStyles}
                getOptionLabel={(data) => data[keys.label]}
                getOptionValue={(data) => data[keys.value]}
                {...props}
                {...field}
                onChange={(data) => handleChange(data, field)}
                menuPortalTarget={document.body}
              />
            )}
          />
        ) : (
          <SelectLib
            ref={ref}
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Nenhum resultado encontrado.'}
            styles={selectStyles}
            defaultValue={defaultValue}
            getOptionLabel={(data) => data[keys.label]}
            getOptionValue={(data) => data[keys.value]}
            {...props}
          />
        )}
        {error && (
          <Row alignItems='center' gap='2px'>
            <Icon icon='Alert' color='danger' size='11' />
            <Typography mt='2px' ml='2px' variant='helper' color='danger' lineHeight='10px'>
              {errorMessage || 'Campo obrigatório'}
            </Typography>
          </Row>
        )}
      </Base>
    )
  },
)

SelectAsync.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  keys: { label: 'label', value: 'value' },
  callBack: null,
}

export default SelectAsync
