import { Column, Input } from 'design-system'
import { MAX_FOOTER_LENGTH, MAX_HEADER_LENGTH } from '../templateOptions'

const headerTypeDict = {
  TEXT: ({ getValues, register, errors }) => (
    <Column>
      <Input
        label='Texto do cabeçalho'
        error={errors?.header && errors?.header?.text}
        errorMessage={errors?.header?.text?.message}
        required
        helperText={`${MAX_HEADER_LENGTH - getValues()?.header?.text?.length} caracteres`}
        {...register('header.text', {
          required: { value: true, message: 'Por favor, insira o texto do cabeçalho da template' },
          maxLength: {
            value: MAX_HEADER_LENGTH,
            message: `O texto do header não pode exceder ${MAX_FOOTER_LENGTH} caracteres.`,
          },
        })}
      />
    </Column>
  ),
}

export default headerTypeDict
