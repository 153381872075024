import { forwardRef } from 'react'
import styled, { css } from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Icon from '../Icon'
import Typography from '../Typography'
import Skeleton from 'design-system/Skeleton'
import TextLabelHelper from 'design-system/TextLabelHelper'
import Row from 'design-system/Row'

const styles = compose(space, layout)

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const InputField = styled.input`
  width: inherit;
  border-radius: 4px;
  font-size: 14px;
  color: ${({ theme: { colors } }) => colors.grey[300]};
  padding: ${({ position, icon }) => (icon ? (position === 'end' ? '5px 36px 5px 8px' : '5px 8px 5px 32px') : '5px 16px')};
  border: ${({ theme: { colors }, error }) => `1px solid ${error ? colors.danger : colors.grey[50]}`};
  &:hover,
  &:focus {
    border: ${({ theme: { colors } }) => `1px solid ${colors.blue[80]}`};

    ${({ hideIconClose }) =>
      hideIconClose &&
      css`
        &::-webkit-search-cancel-button {
          display: none;
        }
        &::-webkit-search-cancel-button {
          display: block;
        }
      `}
  }
  &::placeholder {
    color: ${({ theme: { colors } }) => colors.grey[75]};
  }

  &:disabled {
    background-color: ${({ theme: { colors } }) => colors.grey[40]};
  }
  &:disabled:hover {
    border: ${({ theme: { colors } }) => `1px solid ${colors.grey[50]}`};
    cursor: not-allowed;
  }

  ${styles};
`

const IconWrapper = styled.div`
  position: absolute;
  display: flex;
  top: 6px;
  ${({ position }) => (position === 'end' ? 'right: 6px' : 'left: 6px')};
`

const InputWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
`

const Input = forwardRef(
  (
    {
      label,
      helperText,
      helper,
      icon,
      iconPosition,
      containerProps,
      maxWidth,
      iconProps,
      error,
      errorMessage,
      loading,
      hideIconClose,
      required,
      ...props
    },
    ref,
  ) => {
    return loading ? (
      <Base maxWidth={maxWidth} {...containerProps}>
        {label && <Skeleton width='100px' height='20px' />}
        <Skeleton mt='2px' maxWidth={maxWidth} p='15px' {...containerProps} />
      </Base>
    ) : (
      <Base maxWidth={maxWidth} {...containerProps}>
        <TextLabelHelper label={label} required={required} helper={helper} />
        <InputWrapper>
          <InputField hideIconClose={hideIconClose} ref={ref} icon={icon} position={iconPosition} error={error} {...props} autoComplete='off' />
          {icon && (
            <IconWrapper error={error} label={label} position={iconPosition}>
              <Icon icon={icon} color='grey.100' {...iconProps} />
            </IconWrapper>
          )}
        </InputWrapper>
        {helperText && (
          <Typography mt='4px' ml='2px' variant='caption' fontStyle='italic' lineHeight='10px'>
            {helperText}
          </Typography>
        )}
        {error && (
          <Row mt='4px' alignItems='center' gap='2px'>
            <Icon icon='Alert' color='danger' size='11' />
            <Typography mt='2px' ml='2px' variant='caption' color='danger' lineHeight='10px'>
              {errorMessage || 'Campo obrigatório'}
            </Typography>
          </Row>
        )}
      </Base>
    )
  },
)

Input.defaultProps = {
  type: 'text',
}

export default Input
