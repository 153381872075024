import { colors, defaultColorsTags } from 'configs'
import { Badge, Column, Icon, Row, SelectAsyncCreate, Typography } from 'design-system'
import { getRandomColor, notify } from 'helpers'
import { useEffect, useState } from 'react'
import { createTag, showTagsList } from 'services'

const TagsList = ({ setValue, name, tags }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [tagsList, setTagsList] = useState([])

  const getContactTags = async (values) => {
    const { data } = await showTagsList({ page_size: 1000 })

    if (tags?.length > 0) {
      setTagsList(data.results.filter((f) => values.find((t) => f.id === t.id)))
    } else {
      setTagsList([])
    }
  }

  const handleCreateAndUpdateContact = async (name) => {
    setLoading(true)
    try {
      const { data } = await createTag({
        name,
        color: getRandomColor(defaultColorsTags),
      })

      setTagsList((prevState) => [...prevState, data])

      notify.success('Etiqueta criada com sucesso.')
    } catch (err) {
      notify.error(err?.response?.data?.name[0] || 'Não foi possível realizar ação no momento.')
    } finally {
      setLoading(false)
    }
  }

  const handleChangeTag = (tag) => {
    if (!tag?.id) return

    if (tagsList.find((t) => t?.id === tag?.id)) {
      setTagsList((prevState) => prevState.filter((t) => t.id !== tag.id))
    } else {
      setTagsList(() => [...tagsList, tag])
    }
  }

  useEffect(() => {
    setValue && setValue(name, tagsList)
  }, [tagsList, setValue, name])

  useEffect(() => {
    getContactTags(tags)
  }, [tags])

  const getTagsOptionsSelect = async (search) =>
    (await showTagsList({ page_size: 1000, name__icontains: search })).data.results.map((i) => ({ ...i, label: i.name, value: i.id }))

  return (
    <Column p='8px 16px' backgroundColor={colors.grey[40]} border={`1px solid ${colors.grey[50]}`} borderRadius='8px'>
      <Row gap='8px' onClick={() => setOpen((prev) => !prev)}>
        <Typography fontWeight='600'>Etiquetas</Typography>

        <Badge borderRadius='360px' border={`1px dashed ${colors.grey[300]}`} className='cursor-pointer'>
          <Row gap='6px' alignItems='center'>
            <Icon icon='Plus' color='grey.300' size='11' />
            <Typography>Adicionar</Typography>
          </Row>
        </Badge>
      </Row>
      {open && (
        <Column mt='16px'>
          <SelectAsyncCreate
            placeholder='Pesquisar etiqueta'
            loadOptions={getTagsOptionsSelect}
            loading={loading}
            isCreatable
            onCreateOption={handleCreateAndUpdateContact}
            callBack={handleChangeTag}
            portalTarget={false}
          />
        </Column>
      )}
      <Row mt='8px' flexWrap='wrap' gap='8px'>
        {tagsList.map((tag) => (
          <Badge
            key={tag?.id}
            action={() => tag && handleChangeTag(tag)}
            color={tag?.color}
            backgroundColor={tag?.color}
            fontStyle={{ fontSize: '12px' }}
            title={tag?.name}
          />
        ))}
      </Row>
    </Column>
  )
}

TagsList.defaultProps = {
  name: 'tags',
  setValue: null,
  tags: [],
}

export default TagsList
