import { useEffect, useState } from 'react'

import {
  ModalConfirmation,
  ModalCreateTemplate,
  ModalImportTemplates,
  ModalTemplatePreview,
  PageHeader,
  ProtectedComponent,
  RobotHelp,
} from 'components'
import { Column, Button, Icon, Row, Card, Table, Typography, Tooltip, Select, Search, Badge } from 'design-system'

import { useTemplatesReducer, actionTypes } from './store'
import { notify, withAuthorization } from 'helpers'
import { applyReactionsFlow, deleteSoftTemplate, showReactionsFlow, showTemplatesList } from 'services'
import { templateCategoryDict, templateStatusDict, templateStatusEdit } from 'configs'
import { useDebounce } from 'hooks'

const Templates = () => {
  const [loading, setLoading] = useState(false)
  const [loadingFlows, setLoadingFlows] = useState(false)
  const [reactionsFlowsList, setReactionFlowsList] = useState([])
  const [modalImport, setModalImport] = useState(null)
  const [modalPreview, setModalPreview] = useState(null)
  const [modal, setModal] = useState(null)
  const [modalConfirmation, setModalConfirmation] = useState(false)
  const searchState = useState('')
  const [pagination, setPagination] = useState({
    count: 0,
    page_size: 1000,
    total_pages: 1,
  })

  const [state, dispatch] = useTemplatesReducer()

  const searchDebounce = useDebounce(searchState[0], 1500)

  useEffect(() => {
    fetchReactionsFlows()
  }, [])

  useEffect(() => {
    fetch(searchDebounce)
  }, [pagination?.current_page, searchDebounce])

  const fetch = async (search) => {
    try {
      setLoading(true)
      const { data } = await showTemplatesList({ ...pagination, search })
      dispatch({
        type: actionTypes.LOAD_ITEMS,
        payload: data.results,
      })

      delete data.results

      setPagination({ ...data })
    } catch {
      notify.error('Não foi possível resgatar lista de templates.')
    } finally {
      setLoading(false)
    }
  }

  const fetchReactionsFlows = async () => {
    try {
      setLoadingFlows(true)

      const { data } = await showReactionsFlow()

      setReactionFlowsList(data.results)
    } catch {
      notify.error('Não foi possível listar reações.')
    } finally {
      setLoadingFlows(false)
    }
  }

  const handleDelete = async (id) => {
    try {
      await deleteSoftTemplate(id)
      dispatch({
        type: actionTypes.REMOVE_ITEM,
        payload: id,
      })
      notify.success('Template excluído com sucesso.')
      setModalConfirmation(null)
    } catch {
      notify.error('Não foi possível excluir a template.')
    }
  }

  const handleMergeReactionFlow = async (flow, template) => {
    try {
      setLoadingFlows(true)

      await applyReactionsFlow(flow, {
        message_template: template,
        mode: 'merge',
      })

      notify.success('Reação vinculada com sucesso.')
    } catch {
      notify.error('Não foi possível vincular reação ao template.')
    } finally {
      setLoadingFlows(false)
    }
  }

  const columns = [
    {
      header: 'Nome',
      field: 'name',
      render: (row) => (
        <Typography color='secondary' cursor='pointer' onClick={() => setModalPreview(row)}>
          {row?.name}
        </Typography>
      ),
    },
    {
      header: 'Conexão',
      render: ({ connection }) => <Typography>{connection?.name}</Typography>,
    },
    {
      header: 'Categoria',
      render: ({ category }) => <Typography>{templateCategoryDict[category]?.label}</Typography>,
    },
    {
      header: 'Status',
      render: ({ status }) => (
        <Badge backgroundColor={templateStatusDict[status]?.color} title={templateStatusDict[status]?.label || 'Status não identificado'} />
      ),
    },
    {
      header: 'Reações',
      helper: 'Defina as reações disponíveis para os templates. Elas são as opções de resposta que o contato poderá selecionar.',
      render: ({ id, applied_reaction_flows }) => (
        <Row alignItems='center' maxWidth='320px'>
          <Select
            defaultValue={applied_reaction_flows}
            options={reactionsFlowsList}
            keys={{ label: 'name', value: 'id' }}
            onChange={(flow) => handleMergeReactionFlow(flow.id, id)}
            isLoading={loadingFlows}
            disabled={loadingFlows}
          />
        </Row>
      ),
    },
    {
      header: 'Ações',
      render: (row) => (
        <Row gap='20px'>
          <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
            {templateStatusEdit.includes(row?.status) && (
              <Tooltip title='Editar o template'>
                <Icon cursor='pointer' icon='Edit' color='primary' onClick={() => setModal({ open: true, data: row, edit: true })} />
              </Tooltip>
            )}
            <Tooltip title='Excluir template'>
              <Icon
                cursor='pointer'
                icon='Trash'
                color='danger'
                onClick={() => {
                  setModalConfirmation({
                    type: 'danger',
                    title: `Excluir template ${row?.name}.`,
                    message: 'Ao confirmar, este template será removido permanentemente e não poderá ser recuperado',
                    handler: () => handleDelete(row.id),
                    helper: true,
                    helperProps: {
                      color: 'orange.400',
                      text: 'Se você excluir este template, ele será removido de todos os fluxos aos quais está atrelado.',
                    },
                  })
                }}
              />
            </Tooltip>
          </ProtectedComponent>
        </Row>
      ),
    },
  ]

  return (
    <Column p='24px'>
      <PageHeader title='Templates' />
      <Card mt='16px' gap='24px' overflow='auto'>
        <Typography>
          Templates são modelos de mensagens pré-definidos para facilitar o envio de informações padronizadas. Cada template possui um status que
          indica se foi aprovada para uso.
        </Typography>
        <Card gap='24px'>
          <Row gap='8px' justifyContent='flex-end'>
            <Search searchState={searchState} />
            <Button iconLeft='RefreshAll' variant='outlined' onClick={() => fetch()}>
              Atualizar
            </Button>
            <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
              <Button variant='icon' onClick={() => setModalImport(true)}>
                <Tooltip title='Você pode importar um template usando um código único'>
                  <Icon icon='Download' />
                </Tooltip>
              </Button>
              <Button iconLeft='Plus' onClick={() => setModal({ open: true, data: null })}>
                Novo template
              </Button>
            </ProtectedComponent>
          </Row>
          <Table
            title='template'
            columns={columns}
            loading={loading}
            data={state.templates}
            pagination={pagination}
            setPagination={setPagination}
            emptyMessage='Você ainda não criou um template. Você precisa ter um template para iniciar suas conversas com contatos. Você pode importar utilizando o código único.'
            handleEmptyData={() => setModalImport(true)}
          />
        </Card>
      </Card>
      <RobotHelp
        maxWidth='580px'
        mt='16px'
        text='Os templates são mensagens predefinidas que as empresas podem enviar para seus clientes. Eles são usados para enviar informações estruturadas, como lembretes de compromissos, confirmações de pedidos, atualizações de entrega, entre outros, de forma eficiente e padronizada.'
      />
      {modalImport && <ModalImportTemplates open={modalImport} onClose={() => setModalImport(null)} dispatch={dispatch} actionTypes={actionTypes} />}
      {modalConfirmation && <ModalConfirmation open={modalConfirmation} onClose={() => setModalConfirmation(null)} {...modalConfirmation} />}
      {modalPreview && (
        <ModalTemplatePreview
          open={modalPreview}
          onClose={() => setModalPreview(null)}
          template={modalPreview}
          handler={(data) => setModal({ open: true, data, edit: true })}
        />
      )}
      {modal?.open && (
        <ModalCreateTemplate
          open={modal?.open}
          currentTemplate={modal?.data}
          editing={modal?.edit}
          onClose={() => setModal(null)}
          dispatch={dispatch}
          actionTypes={actionTypes}
        />
      )}
    </Column>
  )
}

export default withAuthorization(Templates, ['access_templates'])
