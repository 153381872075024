export const modalSizes = {
  sm: '420px',
  md: '720px',
  lg: '920px',
  xl: '1280px',
  fullWidth: '100%',
}

export const loaderSizes = {
  big: '128px',
  medium: '64px',
  small: '48px',
}

export const spinnerSizes = {
  sm: '0.3',
  md: '0.6',
  lg: '1.5',
  xlg: '2',
}

export const monthsYear = [
  { label: 'Janeiro', value: 1 },
  { label: 'Fevereiro', value: 2 },
  { label: 'Março', value: 3 },
  { label: 'Abril', value: 4 },
  { label: 'Maio', value: 5 },
  { label: 'Junho', value: 6 },
  { label: 'Julho', value: 7 },
  { label: 'Agosto', value: 8 },
  { label: 'Setembro', value: 9 },
  { label: 'Outubro', value: 10 },
  { label: 'Novembro', value: 11 },
  { label: 'Dezembro', value: 12 },
]

export const weekDays = [
  { label: 'S', suffix: 'Seg', text: 'Segunda-feira', weekday: 0, selected: false },
  { label: 'T', suffix: 'Ter', text: 'Terça-feira', weekday: 1, selected: false },
  { label: 'Q', suffix: 'Qua', text: 'Quanta-feira', weekday: 2, selected: false },
  { label: 'Q', suffix: 'Qui', text: 'Quinta-feira', weekday: 3, selected: false },
  { label: 'S', suffix: 'Sex', text: 'Sexta-feira', weekday: 4, selected: false },
  { label: 'S', suffix: 'Sáb', text: 'Sábado', weekday: 5, selected: false },
  { label: 'D', suffix: 'Dom', text: 'Domingo', weekday: 6, selected: false },
]

export const defaultColorsTags = ['#e8deee', '#dbeddb', '#fadec9', '#d3e5ef', '#ffe2dd', '#fdecc8', '#f5e0e9', '#eee0da']

export const defaultColorsDepartments = ['#42aace', '#1c8f7c', '#e6b656', '#827ca8', '#e27187', '#c15bbd']

export const templateButtonLimit = {
  QUICK_REPLY: 6,
  PHONE_NUMBER: 1,
  URL: 2,
  COPY_CODE: 1,
}

export const templateStatusEdit = ['APPROVED']
