import { forwardRef } from 'react'
import { Controller } from 'react-hook-form'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styled from 'styled-components'
import { space, layout, compose } from 'styled-system'

import Typography from '../Typography'

import { colors } from 'configs'
import Icon from 'design-system/Icon'
import TextLabelHelper from 'design-system/TextLabelHelper'
import Row from 'design-system/Row'

const styles = compose(space, layout)

const circle = (color = 'transparent') => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'transparent',
  gap: '4px',
  ':before': {
    backgroundColor: color,
    borderRadius: 10,
    content: '" "',
    display: 'block',
    height: 10,
    width: 10,
  },
})

const Base = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: fit-content;
  width: 100%;
  ${styles}
`

const NewOptionStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  ${styles}
`

const selectStyles = {
  control: (baseStyles, state) => ({
    ...baseStyles,
    minHeight: '20px',
    border: `1px solid ${state.isFocused ? colors.blue[80] : colors.grey[50]}`,
    padding: '0px',
    color: colors.grey[300],
    fontSize: '14px',
    '&:hover': {
      borderColor: colors.blue[80],
    },
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: '0px 8px',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '1.7px 8px',
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: '1050',
    width: 'max-content',
    minWidth: '100%',
  }),
  singleValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
  multiValue: (styles, { data }) => ({
    ...styles,
    color: colors.grey[300],
    ...circle(data?.color),
  }),
  option: (styles, state) => ({
    ...styles,
    color: colors.grey[300],
    ':hover': { backgroundColor: colors.blue[state.isHovered ? 80 : 50] },
  }),
  menuPortal: (styles) => ({ ...styles, zIndex: 9999 }),
}

const NewOptionField = ({ inputValue }) => (
  <NewOptionStyled alignItems='center' gap='4px'>
    <Icon icon='Plus' size='xs' color='secondary' />
    <Typography textAlign='center' as='span' color='secondary' cursor='pointer'>
      {`Criar opção "${inputValue}"`}
    </Typography>
  </NewOptionStyled>
)

const SelectAsyncCreate = forwardRef(
  (
    {
      label,
      maxWidth,
      keys,
      name,
      control,
      defaultValue,
      error,
      errorMessage,
      required,
      rules,
      loadOptions,
      callBack,
      helper,
      portalTarget,
      disabled,
      ...props
    },
    ref,
  ) => {
    const handleChange = (data, field) => {
      if (field) {
        field.onChange(data)
      }
      if (callBack) {
        callBack(data)
      }
      return ''
    }

    return (
      <Base maxWidth={maxWidth}>
        <TextLabelHelper label={label} required={required} helper={helper} />
        {control ? (
          <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={{ required, ...rules }}
            render={({ field }) => (
              <AsyncCreatableSelect
                ref={ref}
                loadOptions={loadOptions}
                defaultOptions
                loadingMessage={() => 'Carregando...'}
                noOptionsMessage={() => 'Sem opções'}
                getOptionLabel={(data) => data[keys.label] || data.label}
                getOptionValue={(data) => data[keys.value] || data.label}
                formatCreateLabel={(inputValue) => <NewOptionField inputValue={inputValue} />}
                styles={selectStyles}
                isClearable
                {...props}
                {...field}
                isDisabled={disabled}
                onChange={(data) => handleChange(data, field)}
                menuPortalTarget={portalTarget}
              />
            )}
          />
        ) : (
          <AsyncCreatableSelect
            ref={ref}
            loadOptions={loadOptions}
            defaultOptions
            loadingMessage={() => 'Carregando...'}
            noOptionsMessage={() => 'Sem opções'}
            getOptionLabel={(data) => data[keys.label]}
            getOptionValue={(data) => data[keys.value]}
            formatCreateLabel={(inputValue) => <NewOptionField inputValue={inputValue} />}
            styles={selectStyles}
            defaultValue={defaultValue}
            isClearable
            value=''
            isDisabled={disabled}
            onChange={(data) => handleChange(data, { onChange: () => {} })}
            menuPortalTarget={portalTarget}
            {...props}
          />
        )}
        {error && (
          <Row alignItems='center' gap='2px'>
            <Icon icon='Alert' color='danger' size='11' />
            <Typography mt='2px' ml='2px' variant='helper' color='danger' lineHeight='10px'>
              {errorMessage || 'Campo obrigatório'}
            </Typography>
          </Row>
        )}
      </Base>
    )
  },
)

SelectAsyncCreate.defaultProps = {
  options: [],
  placeholder: 'Selecione',
  keys: { label: 'label', value: 'value' },
  callBack: null,
  portalTarget: document.body,
}

export default SelectAsyncCreate
