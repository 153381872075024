export const MAX_BUTTONS_LIMIT = 10
export const MAX_FOOTER_LENGTH = 60
export const MAX_HEADER_LENGTH = 60
export const MAX_CONTENT_LENGTH = 1024

export const templateOptions = {
  categories: [
    {
      key: 'UTILITY',
      name: 'category',
      label: 'Utilidade',
      description: 'Esse tip de modelo serve para enviar informações importantes como atualizações de contas, pedidos, alertas e muito mais.',
    },
    {
      key: 'MARKETING',
      name: 'category',
      label: 'Marketing',
      description: 'Essa tipo de modelo serve para engajamento, serviços, ofertas de produtos e muito mais.',
    },
  ],
}

export const templateTypes = [
  {
    label: 'Texto',
    value: 'TEXT',
  },
]
export const stepRequiredFields = ['connection', 'template_type', 'name', 'tags']

export const categoriesOptions = [
  {
    key: 'UTILITY',
    name: 'category',
    label: 'Utilidade',
    description: 'Esse tipo de modelo serve para enviar informações importantes como atualizações de contas, pedidos, alertas e muito mais.',
  },
  {
    key: 'MARKETING',
    name: 'category',
    label: 'Marketing',
    description: 'Essa tipo de modelo serve para engajamento, serviços, ofertas de produtos e muito mais.',
  },
]

export const templateTypesOptions = [
  {
    label: 'Mensagem de texto',
    value: 'TEXT',
  },
]

export const templateTypesDict = {
  TEXT: { label: 'Texto', value: 'TEXT' },
  CAROUSEL: { label: 'Carousel', value: 'CAROUSEL' },
}

export const headerTypesOptions = [
  {
    label: 'Nenhum',
    value: null,
  },
  {
    label: 'Texto',
    value: 'TEXT',
  },
]

export const languageOptions = [
  {
    label: 'Português',
    value: 'pt_BR',
  },
  {
    label: 'Inglês',
    value: 'en',
  },
  {
    label: 'Espanhol',
    value: 'es',
  },
]

export const initialValues = {
  connection: null,
  category: categoriesOptions[0].key,
  allow_template_category_change: true,
  language: languageOptions[0],
  header: {
    header_type: headerTypesOptions[0],
  },
}

export const actionButtonsOptions = [
  {
    label: 'URL',
    value: 'URL',
    container: 'CTA',
  },
  {
    label: 'Telefone',
    value: 'PHONE_NUMBER',
    container: 'CTA',
  },
  {
    label: 'Respostas rápidas',
    value: 'QUICK_REPLY',
    container: 'QUICK_REPLY',
  },
]

export const buttonsActionsValuesDict = {
  QUICK_REPLY: { button_type: 'QUICK_REPLY', text: '' },
  PHONE_NUMBER: { button_type: 'PHONE_NUMBER', text: '', phone: '' },
  URL: { button_type: 'URL', text: '', url: '' },
}

export const containerActionsValuesDict = {
  QUICK_REPLY: { label: 'Botões | Respostas rápidas', container: 'QUICK_REPLY', buttons: [] },
  CTA: { label: 'Botões | Chamadas para ação', container: 'CTA', buttons: [] },
}

export const actionTypesLimit = {
  QUICK_REPLY: 10,
  PHONE_NUMBER: 1,
  URL: 2,
  TOTAL: 10,
}

export const actionLabelByValue = {
  PHONE_NUMBER: 'Telefone',
  QUICK_REPLY: 'Respostas rápidas',
  URL: 'URL',
  COPY_CODE: 'Código promocional',
}
