import { Button, Column, ListItem, Popover } from 'design-system'
import { useClickout } from 'hooks'
import {
  actionButtonsOptions,
  actionTypesLimit,
  buttonsActionsValuesDict,
  containerActionsValuesDict,
} from 'components/modals/ModalCreateTemplate/templateOptions'

const ActionsButton = ({ append, countTypeLimit, limit, remove, fields, update, setValue, getValues }) => {
  const { triggerRef, elementRef, openedState, setOpenedState } = useClickout(false)

  const handleCleanFields = () => {
    remove()
    setOpenedState(false)
  }

  const handleAddButton = ({ value, container }) => {
    const containerIndex = fields.findIndex((f) => f.container === container)

    if (containerIndex !== -1) {
      const updateButtons = [...fields[containerIndex].buttons, buttonsActionsValuesDict[value]]
      update(containerIndex, { ...fields[containerIndex], buttons: updateButtons })
    } else {
      append({
        ...containerActionsValuesDict[container],
        buttons: [buttonsActionsValuesDict[value]],
      })
    }

    if (containerIndex === 1) {
      setValue(`containers.${container}.buttons`, [...getValues().containers[containerIndex].buttons, buttonsActionsValuesDict[value]])
    }
  }

  return (
    <Column position='relative'>
      <Button ref={triggerRef} iconRight='Arrow' iconProps={{ direction: 'bottom' }} iconLeft='Plus' variant='outlined' color='secondary'>
        Adicionar botão
      </Button>
      {openedState && (
        <Popover open={openedState} ref={elementRef} width='180px' bottom='35px'>
          <ListItem onClick={() => handleCleanFields()}>Nenhum</ListItem>
          {actionButtonsOptions.map(({ label, value, container }, index) => {
            const disabled = countTypeLimit[value] === actionTypesLimit[value] || limit
            return (
              <ListItem key={`${value}_${index}`} onClick={() => (disabled ? undefined : handleAddButton({ value, container }))} disabled={disabled}>
                {label}
              </ListItem>
            )
          })}
        </Popover>
      )}
    </Column>
  )
}

export default ActionsButton
