import { useEffect, useState } from 'react'

import { Badge, Card, Column, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography, Input, Icon } from 'design-system'

import { hexToRGBA, notify } from 'helpers'
import TicketMessage from 'components/specific/tickets/TicketMessage'
import { showTicketMessagesList, searchMessages } from 'services'
import { colors } from 'configs'
import { format } from 'date-fns'
import DateSeparator from 'components/specific/tickets/TicketContainer/DateSeparator'
import { useDebounce } from 'hooks'
import ModalExpandImage from '../ModalExpandImage'

const ModalHistoricTicket = ({ open, onClose, ticket, historicList }) => {
  const [loading, setLoading] = useState(false)
  const [conversation, setConversation] = useState([])
  const [selectedTicket, setSelectedTicket] = useState({})
  const [search, setSearch] = useState('')
  const [loadingSearchMessages, setLoadingSearchMessages] = useState(false)
  const [matchingItemsCount, setMatchingItemsCount] = useState()
  const [messageSearchData, setMessageSearchData] = useState([])
  const [messageIdSearchOrderIdx, setMessageIdSearchOrderIdx] = useState()
  const { contact, attendant, department } = ticket || {}
  const [messageIdSearch, setMessageIdSearch] = useState()
  const [ticketIdSearch, setTicketIdSearch] = useState()
  const [expand, setExpand] = useState(null)

  const debounceSearch = useDebounce(search, 1500)

  useEffect(() => {
    if (ticket?.id) {
      setTicketIdSearch(ticket?.id)
      handleGetConversation(ticket?.id)
    }
  }, [ticket?.id])

  useEffect(() => {
    if (messageIdSearch) {
      scrollToMessage(messageIdSearch)
    }
  }, [messageIdSearch])

  const handleGetConversation = async (id) => {
    try {
      setLoading(true)

      const { data } = await showTicketMessagesList(id, { limit: 9999 })

      const groupedMessages = data.results.reduce((acc, curr) => {
        const key = format(curr.created_at, 'dd/MM/yyyy')

        acc[key] ? acc[key].push(curr) : (acc[key] = [{ ...curr, key }])

        return acc
      }, {})

      setConversation(groupedMessages)
    } catch {
      notify.error('Não foi possível buscar histórico da conversa.')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (selectedTicket?.id) {
      setTicketIdSearch(selectedTicket?.id)
      handleGetConversation(selectedTicket?.id)
    }
    handleCancelSearch()
  }, [selectedTicket])

  const handleCancelSearch = () => {
    setMessageSearchData([])
    setMessageIdSearch(undefined)
    setMatchingItemsCount(undefined)
    setSearch('')
  }

  useEffect(() => {
    handleSearchMessage(debounceSearch)
  }, [debounceSearch])

  const handleSearchMessage = async (content) => {
    if (content) {
      try {
        setLoadingSearchMessages(true)
        const {
          data: { results },
        } = await searchMessages({
          content: content,
          ticket: ticketIdSearch,
          page_size: 9999,
        })
        const resultsLength = results.length
        if (resultsLength === 0) {
          notify.info('Nenhuma mensagem encontrada.')
          return
        }
        const idx = 0
        const newMessageIdSearch = results[idx].ticket_message_id
        setMatchingItemsCount(resultsLength)
        setMessageSearchData(results)
        setMessageIdSearch(results[idx].ticket_message_id)
        setMessageIdSearchOrderIdx(idx)
        scrollToMessage(newMessageIdSearch)
      } catch {
        notify.error('Não foi possível buscar mensagens.')
        setMessageIdSearchOrderIdx()
      } finally {
        setLoadingSearchMessages(false)
      }
    } else {
      handleCancelSearch()
    }
  }

  const handleSearchNavigate = (idx) => {
    if (!isNaN(idx)) {
      let sanitizeIdx = idx
      if (idx > messageSearchData.length - 1) {
        sanitizeIdx = 0
      }
      if (idx < 0) {
        sanitizeIdx = messageSearchData.length - 1
      }
      if (sanitizeIdx !== -1 && messageSearchData.length > 0) {
        setMessageIdSearch(messageSearchData[sanitizeIdx].ticket_message_id)
        setMessageIdSearchOrderIdx(sanitizeIdx)
      }
    }
  }

  const addLeadingZero = (num) => (num < 10 ? `0${num}` : `${num}`)

  const scrollToMessage = (messageId) => {
    const element = document.getElementById(`message_${messageId}_history`)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  const onCloseModal = () => {
    handleCancelSearch()
    onClose()
  }

  return (
    <>
      <Modal open={open} size='lg' closeClickOut onClose={onCloseModal}>
        <ModalHeader onClose={onCloseModal}>
          <Column>
            <Typography variant='title' color='primary'>
              {contact?.name}
            </Typography>
            <Typography>{`Responsável: ${attendant?.name || 'Sem atendente'} | ${department?.name || 'Sem departamento'}`}</Typography>
          </Column>
        </ModalHeader>

        <ModalBody>
          <Row
            marginBottom='16px'
            width='100%'
            height='50px'
            background={colors.grey[90]}
            padding='8px'
            display='flex'
            alignItems='center'
            position='relative'
          >
            <Input
              hideIconClose
              type='search'
              icon='Search'
              iconProps={{ color: 'grey.200' }}
              placeholder='Pesquisar na conversa'
              value={search}
              onChange={(event) => {
                handleCancelSearch()
                setSearch(event.target.value.toLowerCase())
              }}
            />
            {!loadingSearchMessages && matchingItemsCount !== undefined && !!messageSearchData.length && (
              <Row position='absolute' right='94px' fontSize='14px' color={colors.grey[100]} style={{ userSelect: 'none' }}>
                {`${addLeadingZero(messageIdSearchOrderIdx + 1)}/${addLeadingZero(matchingItemsCount)}`}
              </Row>
            )}
            <Icon
              icon='Arrow'
              marginLeft='24px'
              cursor='pointer'
              color={colors.grey[300]}
              onClick={() => handleSearchNavigate(messageIdSearchOrderIdx + 1)}
            />
            <Icon
              icon='Arrow'
              cursor='pointer'
              direction='bottom'
              marginLeft='8px'
              color={colors.grey[300]}
              onClick={() => handleSearchNavigate(messageIdSearchOrderIdx - 1)}
            />
          </Row>
          <Card p='0' background={colors.grey[40]}>
            <Row>
              <Column
                width='100%'
                p='10px 10px 0'
                height='80%'
                minHeight='300px'
                maxHeight='500px'
                flexDirection='column-reverse'
                gap='16px'
                overflow='auto'
              >
                {!loading ? (
                  <Column width='100%' flexDirection='column-reverse' height='80%' overflow='auto'>
                    {Object.keys(conversation).map((date) => (
                      <Column position='relative' p='10px 10px 0'>
                        <Column flexDirection='column-reverse' gap='8px'>
                          {conversation[date].map((message) => (
                            <TicketMessage type='history' message={message} setExpand={setExpand} search={search} messageIdSearch={messageIdSearch} />
                          ))}
                          <DateSeparator date={date} />
                        </Column>
                      </Column>
                    ))}
                  </Column>
                ) : (
                  <Column>
                    <Spinner size='lg' />
                  </Column>
                )}
              </Column>
              <Column p='10px' width='450px' border={`1px solid ${colors.grey[40]}`}>
                <Column maxHeight='500px' overflow='auto'>
                  {historicList.length > 0 &&
                    historicList.map(({ closed_at, department, attendant, id, contact }, idx) => (
                      <Column
                        key={idx}
                        className='cursor-pointer'
                        p='8px'
                        borderBottom={`1px solid ${colors.grey[50]}`}
                        onClick={() => setSelectedTicket({ id, contact, attendant, closed_at, department })}
                      >
                        <Row width='100%' justifyContent='space-between'>
                          <Row gap='4px' width='100%'>
                            <Typography>{format(closed_at, 'dd/MM/yyyy HH:mm')}</Typography>
                          </Row>
                          {department && (
                            <Badge
                              width='100px'
                              backgroundColor={hexToRGBA(department?.color, 0.2)}
                              color={department?.color}
                              title={department?.name}
                              fontStyle={{ fontSize: '12px' }}
                            />
                          )}
                        </Row>
                        <Row>
                          <Typography fontSize='12px'>{`Responsável: ${attendant?.name || 'Sem responsável'}`}</Typography>
                        </Row>
                      </Column>
                    ))}
                </Column>
              </Column>
            </Row>
          </Card>
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>

      {expand && <ModalExpandImage closeClickOut open={expand} onClose={() => setExpand(null)} data={expand} />}
    </>
  )
}

export default ModalHistoricTicket
