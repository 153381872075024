import { ActionField } from 'components/specific/templates/CreateTemplate'
import { Column, Icon, Row, Typography } from 'design-system'
import { dragEnd, dragStart, drop } from 'helpers'
import { useRef } from 'react'

const ActionContainer = ({ field, containerIndex, move, remove, removeField, errors, register, ...props }) => {
  const { label, buttons } = field || {}

  const containerRef = useRef()

  return (
    <Column
      ref={containerRef}
      draggable='true'
      onDragStart={(e) => dragStart(e, containerIndex)}
      onDragEnd={dragEnd}
      onDragOver={(e) => e.preventDefault()}
      onDrop={(e) => drop(e, containerIndex, move)}
      p='16px'
      gap='16px'
      backgroundColor='grey.25'
      borderRadius='8px'
      {...props}
    >
      <Row justifyContent='space-between'>
        <Typography fontSize='16px' fontWeight='600'>
          {label}
        </Typography>
        <Row gap='4px'>
          <Icon icon='Trash' color='danger' cursor='pointer' onClick={() => remove(containerIndex)} />
          <Icon icon='Drag' color='text' cursor='pointer' />
        </Row>
      </Row>
      <Column>
        {buttons.map((field, index) => (
          <ActionField
            id={field.id}
            key={field.id}
            name={`containers.${containerIndex}.buttons.${index}`}
            register={register}
            index={index}
            containerIndex={containerIndex}
            errors={errors}
            field={field}
            removeField={removeField}
          />
        ))}
      </Column>
    </Column>
  )
}

export default ActionContainer
