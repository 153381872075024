import styled from 'styled-components'
import { Card, RadioButton, Row, Typography } from 'design-system'
import { useFormContext } from 'react-hook-form'
import { theme } from 'configs'

const CategorySelection = ({ options, disabled }) => {
  const { register, setValue, watch } = useFormContext()

  const handleSelectCategory = (key, value) => setValue(key, value)

  const selectedCategory = watch('category')

  return (
    <Row gap='16px' width='100%' flexWrap={['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap']}>
      {options.map(({ key, name, label, description }) => (
        <CustomCard
          maxWidth={['100%', '100%', '100%', '431px', '431px']}
          width='100%'
          key={key}
          active={selectedCategory === key}
          checked
          onClick={() => (disabled ? undefined : handleSelectCategory(name, key))}
          p='16px'
          disabled={disabled}
        >
          <RadioButton label={label} value={key} {...register(name)} disabled={disabled} />
          <Typography mt='8px' ml='22px' notSelectable>
            {description}
          </Typography>
        </CustomCard>
      ))}
    </Row>
  )
}

const CustomCard = styled(Card)`
  background-color: ${({ active }) => (active ? theme.colors.blue[60] : theme.colors.white)};
  border: ${({ active }) => (active ? `1px solid ${theme.colors.blue[80]}` : `1px solid ${theme.colors.grey[50]}`)};

  cursor: pointer;
  & * {
    cursor: pointer;
  }

  &:hover {
    border: ${({ theme }) => `1px solid ${theme.colors.blue[80]}`};
  }
`

CategorySelection.defaultProps = {
  label: 'Label',
  description: 'Descrição',
}

export default CategorySelection
