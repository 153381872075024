import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import Cookies from 'js-cookie'

import { Row, Form, Input, Button, Image, Typography, Checkbox, Column } from 'design-system'

import { system } from 'configs'

import { login } from 'services'

import { useAuth, useStorage } from 'hooks'

import { chatGDSLogo1 } from 'assets'
import { useNavigate } from 'react-router-dom'
import { notify } from 'helpers'
import { Alert } from 'components/common'

const Login = () => {
  const { COOKIE_TOKEN } = system
  const [loading, setLoading] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const [storeCredential, setStoreCredential] = useState(false)
  const [invalidCredentials, setInvalidCredentials] = useState(null)

  const { updateLoggedUser, userActionTypes } = useAuth()
  const { setStorage, getStorage } = useStorage()

  const navigate = useNavigate()

  const hasCookie = Cookies.get(COOKIE_TOKEN)

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    const credential = getStorage('credential')

    if (credential) {
      setValue('identifier', credential)
      setStoreCredential(true)
    }

    if (hasCookie) {
      return navigate('/tickets')
    }
  }, [])

  const onSubmit = async (values) => {
    try {
      setLoading(true)

      if (storeCredential) {
        setStorage('credential', values.identifier)
      }

      const { data } = await login(values)

      Cookies.set(COOKIE_TOKEN, data.token)

      const permissions = data?.session?.module_actions

      updateLoggedUser({
        type: userActionTypes.ADD_ITEM,
        payload: { ...data, permissions },
      })

      if (!data?.company_user) return navigate('/settings')

      navigate('/tickets')
    } catch (err) {
      if (err?.response?.data?.detail === 'Email ou senha incorretos') {
        setInvalidCredentials(true)
      } else {
        notify.error('Não foi possível acessar o ChatGDS no momento.')
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Row
      alignItems='center'
      justifyContent='center'
      height='100%'
      p='20px'
      minHeight='100vh'
      backgroundColor='rgb(99,81,172)'
      backgroundImage='linear-gradient(90deg, rgba(17,23,42,1) 0%, rgba(99,81,172,1) 52%, rgba(17,23,42,1) 95%)'
    >
      <Form maxWidth='380px' p='30px' border='1px solid #E8E8E8' borderRadius='5px' backgroundColor='#fff' onSubmit={handleSubmit(onSubmit)}>
        <Image src={chatGDSLogo1} />
        <Typography variant='caption' textAlign='center'>
          Bem-vindo! insira seu email e senha para entrar no ChatGDS.
        </Typography>
        {invalidCredentials && <Alert mt='16px' color='danger' text='Seu e-mail ou senha estão incorretos.' />}
        <Row mt='24px'>
          <Input placeholder='Digite seu e-mail' icon='Email' error={errors?.identifier} {...register('identifier', { required: true })} />
        </Row>
        <Row mt='24px'>
          <Input
            type={showPassword ? 'text' : 'password'}
            placeholder='Digite sua senha'
            icon={showPassword ? 'Eye' : 'ClosedEye'}
            error={errors?.password}
            iconProps={{
              onClick: () => setShowPassword((prevState) => !prevState),
            }}
            {...register('password', { required: true })}
          />
        </Row>
        <Row mt='16px' justifyContent='space-between'>
          <Checkbox label='Lembre de mim' checked={storeCredential} onChange={() => setStoreCredential((prevState) => !prevState)} />
          <Typography color='secondary' fontWeight='600' cursor='pointer' onClick={() => navigate('/reset-password/')} notSelectable>
            Esqueci a senha
          </Typography>
        </Row>
        <Column mt='24px' gap='16px'>
          <Button type='submit' width='100%' disabled={loading}>
            Entrar
          </Button>
          <Button width='100%' variant='outlined' onClick={() => navigate('/register')}>
            Registrar
          </Button>
        </Column>
      </Form>
    </Row>
  )
}

export default Login
