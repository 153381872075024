import { Image, Row } from 'design-system'

import { useAuth } from 'hooks'
import UserMenu from './UserMenu'
import { chatGDSHeader } from 'assets'
import CompanyMenu from './CompanyMenu'

const Header = ({ open }) => {
  const { userData } = useAuth()

  return (
    <Row
      position='fixed'
      width='100%'
      zIndex='999'
      background='linear-gradient(68.7deg, #12182B -23.89%, #6351AC 110.76%)'
      minHeight='60px'
      alignItems='center'
      justifyContent='space-between'
      p={open ? '0 16px' : '0 16px 0 112px'}
    >
      <Row>
        <Image width='110px' height='auto' src={chatGDSHeader} />
      </Row>
      <Row alignItems='center' gap='16px'>
        {userData?.company_user && <CompanyMenu company={userData?.company_user?.company} />}
        <UserMenu user={userData?.user} />
      </Row>
    </Row>
  )
}

export default Header
