import { PreviewTemplateCard, ProtectedComponent } from 'components'
import { Alert } from 'components/common'
import { colors, languageDict, templateCategoryDict, templateStatusDict, templateStatusEdit, templateTypeDict } from 'configs'
import { Badge, Button, Card, Column, HighlightedText, Modal, ModalBody, ModalFooter, ModalHeader, Row, Typography } from 'design-system'

const ModalTemplatePreview = ({ open, onClose, template, handler }) => {
  const { name, connection, category, status, language, template_type, rejected_reason } = template || {}

  const templateStatus = templateStatusDict[status]

  return (
    <Modal open={open} onClose={onClose} closeClickOut>
      <ModalHeader onClose={onClose}>
        <Typography fontWeight='bold' fontSize='24px' color='primary'>
          {`Template: ${name}`}
        </Typography>
      </ModalHeader>
      <ModalBody>
        <Column>
          <Alert
            mt='16px'
            icon='Information'
            title={templateStatus?.title}
            text={templateStatus?.text}
            color={templateStatus?.statusColor}
            helper={rejected_reason}
          />
        </Column>
        <Row mt='16px' p='16px' borderRadius='8px' background={colors.grey[25]}>
          <PreviewTemplateCard
            p='16px'
            alignItems='center'
            hiddenTitle
            mt='0'
            template={{ ...template, template_type: { value: template.template_type }, fieldGroup: { buttons: template.buttons } }}
          />
          <Column width='100%' alignItems='flex-end'>
            <ProtectedComponent allowedRoles={['manage_templates']} unauthorizedComponent={false}>
              {templateStatusEdit.includes(status) && (
                <Button
                  variant='text'
                  iconLeft='Edit'
                  color='secondary'
                  onClick={() => {
                    handler(template)
                    onClose()
                  }}
                >
                  Editar
                </Button>
              )}
            </ProtectedComponent>
            <Card mt='16px' p='8px' width='100%'>
              <Typography fontSize='16px' fontWeight='600'>
                Configuração
              </Typography>
              <Column mb='8px' height='1px' backgroundColor='grey.50' />
              <Row justifyContent='space-between'>
                <HighlightedText hightLight='Conexão' text={connection?.name} p='2px 0px' border='none' />
                <Badge
                  noTooltip
                  backgroundColor={templateCategoryDict[category]?.color}
                  title={templateCategoryDict[category]?.label || 'Status não identificado'}
                />
              </Row>
              <HighlightedText hightLight='Tipo de template' text={templateTypeDict[template_type]?.label} p='2px 0px' border='none' />
              <HighlightedText hightLight='Idioma' text={languageDict[language]?.label} p='2px 0px' border='none' />
            </Card>
          </Column>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button onClick={onClose}>Fechar</Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalTemplatePreview
