import styled, { css } from 'styled-components'

import { Icon, Row, Tooltip } from 'design-system'
import MoreButton from 'components/common/MoreButton'

const HeaderButtons = ({ actions, moreActions, closed_at }) => {
  return (
    <Row position='relative' gap='8px'>
      <Base>
        {actions.map((action, idx) => (
          <Tooltip key={idx} position='bottom' title={action?.tooltip || ''}>
            <Custom
              padding='8px'
              onClick={() => (action?.disabled ? undefined : action.handler())}
              disabled={action.disabled}
              active={action.active}
              id={action.id}
            >
              <Icon icon={action.icon} color={action?.disabled ? 'text' : 'primary'} />
            </Custom>
          </Tooltip>
        ))}
      </Base>
      <MoreButton
        actionsList={moreActions}
        tooltip={
          closed_at ? 'Não é possível realizar a alteração, pois a conversa está encerrada.' : ''
        }
        disabled={closed_at}
      />
    </Row>
  )
}

const Base = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(197, 192, 227, 0.1);
  border-radius: 8px;
  border: 1px solid #e8e8e8;
`

const buttonVariant = {
  search: css`
   border-top-left-radius: 8px;
   border-bottom-left-radius: 8px;
  `,
  check: css`
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  `,
  switch: css`
    border-radius: none;
  `,
}

const Custom = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;

  ${({ id }) => buttonVariant[id]}
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  background-color: ${({ active }) => (active ? 'rgba(197, 192, 227, 0.3)' : '')};

  &:hover {
    background: rgba(197, 192, 227, 0.3);
  }
`

export default HeaderButtons
