import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { Column, Form, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Typography } from 'design-system'
import { formatBrokerName, formatButtonsToContainers, formatContainersToButtons, notify } from 'helpers'
import Footer from './Footer'
import BasicInfos from './BasicInfos'

import { initialValues, languageOptions, templateTypesDict } from './templateOptions'
import ModelConfig from './ModelConfig'
import PreviewTemplate from 'components/specific/templates/PreviewTemplateCard'
import { createTemplate, existBrokerName, updateTemplate } from 'services'

const ModalCreateTemplate = ({ open, onClose, currentTemplate, editing, dispatch, actionTypes }) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [loading, setLoading] = useState(false)

  const methods = useForm({
    defaultValues: currentTemplate
      ? {
          ...currentTemplate,
          template_type: templateTypesDict[currentTemplate.template_type],
          language: languageOptions.find((i) => i.value === currentTemplate.language),
          header: { ...currentTemplate.header, header_type: currentTemplate?.header?.text ? { value: 'TEXT', label: 'Texto' } : null },
          containers: currentTemplate?.buttons?.length > 0 ? formatButtonsToContainers(currentTemplate?.buttons) : [],
        }
      : initialValues,
  })

  const watchValues = methods.watch()

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      values.tags = values?.tags?.map((t) => t.id)
      values.connection = values.connection.id
      values.language = values.language.value
      values.template_type = values.template_type.value
      values.broker_name = formatBrokerName(values?.name)
      values.buttons = formatContainersToButtons(values?.containers)

      if (values?.header?.header_type) {
        values.header = {
          header_type: values.header.header_type.value,
          text: values.header.text,
        }
      } else {
        delete values.header
      }

      const { data } = editing ? await updateTemplate(currentTemplate.id, values) : await createTemplate(values)
      dispatch({ type: editing ? actionTypes.UPDATE_ITEM : actionTypes.ADD_ITEM, payload: data })
      notify.success('Template criado com sucesso.')
      setLoading(false)
      onClose()
    } catch {
      setLoading(false)
      notify.error('Não foi possível criar template. Verifique as informações.')
    }
  }

  const checkExistBrokerName = async () => {
    try {
      if (editing) {
        setCurrentStep(1)
        return true
      }

      setLoading(true)
      const { data } = await existBrokerName(formatBrokerName(watchValues.name))
      if (data?.exists) {
        methods.setError('name', { message: 'Você já possui um template com este nome' })
        return true
      }
      setCurrentStep(1)
    } catch {
      notify.error('Erro ao verificar nome do broker. Verifique as informações.')
    } finally {
      setLoading(false)
    }
  }

  const handleChangePreviousStep = () => setCurrentStep((prev) => prev - 1)

  return (
    <Modal size='xl' open={open}>
      <FormProvider {...methods}>
        <Form onSubmit={methods.handleSubmit(onSubmit)}>
          <ModalHeader onClose={onClose}>
            <Typography fontSize='24px' fontWeight='600' color='primary'>
              {`${editing ? 'Editar' : 'Novo'} modelo de mensagem | Templates`}
            </Typography>
          </ModalHeader>
          <ModalBody>
            <Row gap='16px'>
              <Column width='100%' maxHeight='700px' overflow='auto'>
                {currentStep === 0 && <BasicInfos editing={editing} />}
                {currentStep === 1 && <ModelConfig />}
              </Column>
              <PreviewTemplate key='template' template={{ ...watchValues, buttons: formatContainersToButtons(watchValues?.containers) }} />
            </Row>
            {loading && (
              <Row p='8px 18px' alignItems='center' justifyContent='flex-end' gap='16px'>
                <Typography>Aguarde...</Typography>
                <Column>
                  <Spinner size='sm' />
                </Column>
              </Row>
            )}
          </ModalBody>
          <ModalFooter>
            <Footer loading={loading} currentStep={currentStep} next={checkExistBrokerName} previous={handleChangePreviousStep} />
          </ModalFooter>
        </Form>
      </FormProvider>
    </Modal>
  )
}

export default ModalCreateTemplate
